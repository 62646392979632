<template>
  <div class="main-wrapper">
    <div class="row" style="margin-top:90px"> 
        <div class="col-md-6 ml-3">
          <img src="/static/images/sports-bar-new-screen.png" alt="" style="width:100%">
        </div>
        <div class="col-md-5 ml-4">
         <div class="row">
           <div class="col-md-12">
              <img src="/static/images/demo-map.png" alt="" style="width:100%">
           </div>
            <div class="col-md-12">
              <div class="row mt-4">
                <div class="col-md-5">
                  <img src="/static/images/zipcodepro-logo.png" alt="" style="width:100%">
                </div>
                <div class="col-md-6">
                   <a href="#"   style="color:#0f7acf">	<b>Visit Directory</b> </a>
                </div>
              </div>
              <img src="/static/images/demo-ad.png" alt="" style="width:60%" class="shadow p-3 mb-5 bg-white rounded">
           </div>
         </div>
        </div>
    </div>
  </div>
</template>
<script>
export default {
};
</script>
